import {
    $authHost,
    // eslint-disable-next-line
    $host
} from ".";

export const createBasket = async (basketProduct) => {
    const {
        data
    } = await $authHost.post('api/basket', basketProduct)
    return data
}

export const fetchBasket = async(userId) =>{
    const {
        data
    } = await $authHost.get('api/basket', {
        params: {
            userId
        }
    })
    return data
}

export const deleteBasketProduct = async(id) => {
    const {
        data
    } = await $authHost.delete('api/basket/delOne', {
        params: {
            id
        }
    })
    return data
}

export const deleteAllBasketProduct = async(userId) => {
    const {
        data
    } = await $authHost.delete('api/basket/delAll', {
        params: {
            userId
        }
    })
    return data
}

export const sendBasketProduct = async(cart) => {
    const {
        data
    } = await $authHost.post('api/email/sendCart', cart)
    return data
}

export const editBasketProduct = async(basketProduct) => {
    const {
        data
    } = await $authHost.put('api/basket', basketProduct)
    return data
}