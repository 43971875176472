import React, { useContext, useState } from 'react'
import { Container, Button, Form, Card } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { login, registration } from '../http/userAPI'
import { LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE } from '../utils/consts'
import { observer } from 'mobx-react-lite'
import { Context } from '..'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import { v4 } from 'uuid'

export default observer(function Auth() {
  const { user } = useContext(Context)
  const location = useLocation()
  const history = useNavigate()
  const isLogin = location.pathname === LOGIN_ROUTE

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [nameCompany, setNameCompany] = useState('')
  const [inn, setInn] = useState('')
  const [face, setFace] = useState('PHYSIC')

  const [checked, setChecked] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState('')
  const [formError, setFormError] = useState([])
  let errors = []

  const handleChange = (event) => {
    errors = []
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFace('LAWYER')
    } else {
      setFace('PHYSIC')
    }
  };

  const sendFormAuth = async () => {
    try {
      let data
      if (isLogin) {
        await login(email, password).then((res) => {
          user.setUser(res)
          user.setIsAuth(true)
          history(SHOP_ROUTE)
        }).catch(err => {
          setError(err.response.data.message)
        })
      } else {
        if (checked) {
          data = await registration(email, password, face, nameCompany, inn).then((res) => {
            user.setUser(res)
            user.setIsAuth(true)
            history(SHOP_ROUTE)
          }).catch(err => {
            setError(err.response.data.message)
          })
        } else {
          data = await registration(email, password, face).then((res) => {
            user.setUser(res)
            user.setIsAuth(true)
            history(SHOP_ROUTE)
          }).catch(err => {
            setError(err.response.data.message)
          })
        }
      }

    } catch (error) {
      alert(error.message);
    }
  }

  const click = async () => {
    errors = []
    setFormError([])
    if (email.length < 10) {
      errors.push('Некорректный Email')
      setFormError(errors)
    } else {
      const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!patternEmail.test(email)) {
        errors.push('Некорректный Email');
        setFormError(errors);
        setEmail('');
      }
    }

    if (!isLogin && checked) {
      if (nameCompany.length < 2) {
        errors.push('Некорректное название компании')
        setFormError(errors)
        setNameCompany('')
      }
      if (inn.length > 12 || inn.length < 10) {
        errors.push('Некорректный ИНН')
        setFormError(errors)
        setInn('')
      }
    }
    if (errors.length <= 0) {
      sendFormAuth()
    }
  }

  return (
    <div>
      <Container
        className='d-flex justify-content-center align-items-center'
        style={{ height: window.innerHeight - 280 }}
      >
        <Card style={{ width: 600 }} className="p-5">
          <h2 className='m-auto'> {isLogin ? "Авторизация" : "Регистрация"}</h2>
          <Form className="d-flex flex-column">
            <Form.Control
              type='email'
              className='mt-3'
              placeholder='Введите ваш email'
              value={email}
              autoComplete="on"
              onChange={e => { setEmail(e.target.value); setFormError([]); setError('') }}
            />
            <Form.Control
              className='mt-3'
              placeholder='Введите ваш пароль'
              value={password}
              autoComplete="on"
              onChange={e => { setPassword(e.target.value); setFormError([]); setError('') }}
              type='password'
            />
            {(checked && !isLogin) &&
              <FormGroup>
                <Form.Control
                  className='mt-3'
                  placeholder='Введите название компании'
                  value={nameCompany}
                  onChange={e => { setNameCompany(e.target.value); setFormError([]); setError('') }}
                  type='text'
                />
                <Form.Control
                  className='mt-3'
                  placeholder='Введите ИНН компании'
                  value={inn}
                  onChange={e => { setInn(e.target.value); setFormError([]); setError('') }}
                  type='text'
                />
              </FormGroup>
            }

            {isLogin ? ''
              :
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    size="small"
                  />
                }
                label="Юридическое лицо?"
              />
            }

            <div className='d-flex justify-content-between mt-3'>
              {isLogin ?
                <div>
                  Нет аккаунта? <NavLink to={REGISTRATION_ROUTE} onClick={() => { setError(''); setFormError([]) }}>Зарегистрируйся!</NavLink>
                </div>
                :
                <div>
                  Есть аккаунт? <NavLink to={LOGIN_ROUTE} onClick={() => { setError(''); setFormError([]) }}>Войдите!</NavLink>
                </div>
              }

              <Button
                disabled={email === '' || password === '' ? true : false}
                onClick={click}
                variant={"outline-success"}>
                {isLogin ? "Войти" : "Регистрация"}
              </Button>
            </div>
            {formError.length > 0 &&
              <div style={{ color: "red", textAlign: 'center' }}>
                {
                  formError.map((value, index) =>
                    <div key={v4()}>
                      <label key={v4()}>{value}</label>
                      <br></br>
                    </div>
                  )
                }
              </div>
            }
            {error.length > 0 && <div style={{ color: "red", textAlign: 'center' }}>{error}</div>}
          </Form>
        </Card>
      </Container>
    </div>
  )
})
