import React from 'react'
import { Card, Container } from 'react-bootstrap'

export default function OrderPayment() {

  const list = [
    "Выберите необходимые товары, нажмите кнопку «В корзину»; или «быстрый заказ»",
    "Перейдите в раздел «Корзина»",
    "Укажите номер телефона и имя получателя заказа",
    "Установите удобные для Вас условия доставки (полный адрес) и оплаты, после чего нажмите кнопку «Отправить». После чего с Вами свяжется оператор, уточнит о наличии товара и оформит заказ",
    "Для бесплатной доставки по г. Красноярску, минимальная сумма заказа от 5000 руб.",
    "Доставка по Красноярскому краю осуществляется после 100 % оплаты за выбранный товар",
    "Вы можете оплатить наличными курьеру службы доставки при получении товара",
    "Вы можете оплатить онлайн переводом при получении товара на пункте выдачи или при получении товара от курьера. Мы принимаем карты Visa, MasterCard, Maestro, Мир"
  ]

  return (
    <Container className='d-flex flex-column' >
      <h2 style={{ textAlign: "center" }}>ЗАКАЗ И ОПЛАТА</h2>
      <Card style={{ padding: '30px' }}>
        <h3>Условия оплаты</h3>
        <div className='wrapList'>
          <ul className='list'>
            {
              list.map((value, index) =>
                <li key={index} className='listItem'>{value}</li>
              )
            }
          </ul>
        </div>
      </Card>
    </Container>
  )
}
