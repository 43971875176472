import React from 'react'
import { Card, Container } from 'react-bootstrap'

export default function Vacancies() {
  return (
    <Container>
      <h2 style={{ textAlign: "center" }}>ВАКАНСИИ</h2>
      <Card style={{ padding: '30px' }}>
        <p>
          <strong>Менеджер по продажам интернет-склада</strong>
          <br></br>
          Наличие авто обязательно
          <br></br>
          Проживание недалеко от места работы
          <br></br>
          В обязанности входит: отвечать на телефон и консультация покупатлей о наличии товара и его характеристиках, желательно знание хозяйственных товаров и инструмента, сборка интернет заказов и отправка их с помощью курьеров
          <br></br>
          Пятидневка: суббота и воскресение – выходной, работа с 9:00 до 18:00.
          <br></br>
          З/П два раза в месяц
        </p>
      </Card>
    </Container>
  )
}
