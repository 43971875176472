import React from 'react'
import { Card, Container } from 'react-bootstrap'

import '../scss/pages/_aboutCompany.scss'

export default function AboutCompany() {
  return (
    <Container>
      <h2 style={{ textAlign: 'center' }}>О КОМПАНИИ</h2>
      <Card style={{ padding: '30px' }}>
        <p>ХОЗОПТСКЛАД.РФ – это первый онлайн проект интернет-склада по Красноярскому краю</p>
        <p>
          На нашем складе хозяйственных товаров присутствуют более 50000 наименований разных категорий, практически
          всегда в наличии в Красноярске!
        </p>
        <p>Все позиции смотрите в нашем каталоге!</p>
        <p>
          Уникальность нашего склада заключается в том, что клиент может выбрать и приобрести любой товар по оптовой
          цене, дешевле на 30-100% чем в магазинах, при этом, не выходя из дома, а продуманная логистическая система,
          доставит его клиенту в любой населённый пункт Красноярского края
        </p>
        <p>
          У нас нет красиво оформленных выставочных залов и большого количества сотрудников, за которых нужно платить,
          поэтому наши цены всегда самые НИЗКИЕ по Красноярскому краю!
        </p>
        <p>
          С каждым днём мы расширяем пункты выдачи в районных центрах и посёлков Красноярского края, где Вы можете
          заказать и получить товар
        </p>
        <p>Весь товар сертифицированный и соответствует ГОСТу</p>
        <p>Гарантия действует 12 месяцев, практически на все виды товаров</p>
        <p>Сервисный центр находится в Красноярске на ул. Караульная 5 в</p>
        <p>У нас к сожалению нет скидок до 50000, так как у нас САМЫЕ низкие цены по Красноярску</p>
        <p>
          Наши цены вы можете сравнить в интернете и с такими гипермаркетами как с Леруа Мерлен, после чего Вы будете
          приятно удивлены
        </p>
        <p>Мы работаем с понедельника по пятницу с 10:00 до 18:00</p>
        <p>В субботу мы работаем только на доставку.</p>
      </Card>
    </Container>
  )
}
