import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Context } from '..'
import { fetchProducts } from '../http/productAPI'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import SendOrder from '../components/modals/SendOrder'

import '../scss/pages/_basket.scss'
import { Button } from '@mui/material'
import { deleteBasketProduct, editBasketProduct, fetchBasket } from '../http/basketAPI'
import { PRODUCT_ROUTE } from '../utils/consts'
import { useNavigate } from 'react-router-dom'

import { v4 } from 'uuid'
import { configure } from 'mobx'
import { logDOM } from '@testing-library/react'

configure({
  enforceActions: 'never',
})

export default observer(function Basket() {
  const { product } = useContext(Context)
  const { user } = useContext(Context)
  const history = useNavigate()

  const [addPhoneVisible, setAddPhoneVisible] = useState(false)

  const [countValue, setCountValue] = useState()
  const [editCount, setEditCount] = useState(false)
  const [editRow, setEditRow] = useState()
  const [sumPrice, setSumPrice] = useState(0)
  const [blockOrder, setBlockOrder] = useState(false)

  let sumTemp = 0

  const columns = [
    { field: 'delete', headerName: 'Удалить' },
    { field: 'article', headerName: 'Артикул' },
    { field: 'img', headerName: 'Изображение' },
    { field: 'name', headerName: 'Наименование' },
    { field: 'count', headerName: 'Кол-во' },
    { field: 'cost', headerName: 'Цена' },
  ]

  const editProductCard = device => {
    if (user.isAuth) {
      editBasketProduct({
        userId: user.user.id,
        productId: device.id,
        count: device.count,
        cost: device.cost * device.count,
      }).then(data => {
        data[0].forEach((item, index) => {
          Object.assign(item, {
            idRow: data[1][index]['id'],
            count: data[1][index]['count'],
            cost: data[1][index]['cost'],
          })
        })
        product.setBasket(data[0])
        product.basket.forEach(value => {
          sumTemp += +value.cost
        })
        setSumPrice(sumTemp)
      })
    } else {
      let basket = []

      basket = JSON.parse(localStorage.getItem('cart'))
      for (let i = 0; i < basket.length; i++) {
        if (basket[i].id === device.id) {
          basket[i].cost = Math.round((basket[i].cost / basket[i].count) * device.count)
          basket[i].count = device.count
          break
        }
      }
      localStorage.cart = JSON.stringify(basket)
      product.setBasket(JSON.parse(localStorage.getItem('cart')))
      product.basket.forEach(value => {
        sumTemp += +value.cost
      })
      setSumPrice(sumTemp)
    }
  }

  const deleteBasketProd = idRow => {
    if (user.isAuth) {
      deleteBasketProduct(idRow).then(data1 => {
        fetchBasket(user.user.id).then(data => {
          data[0].forEach((item, index) => {
            Object.assign(item, {
              idRow: data[1][index]['id'],
              count: data[1][index]['count'],
              cost: data[1][index]['cost'],
            })
          })
          product.setBasket(data[0])
          product.basket.forEach(value => {
            sumTemp += +value.cost
          })
          setSumPrice(sumTemp)
        })
      })
    } else {
      let basket = JSON.parse(localStorage.getItem('cart'))
      basket.splice(idRow, 1)
      product.setBasket(basket)
      localStorage.cart = JSON.stringify(basket)
      product.basket.forEach(value => {
        sumTemp += +value.cost
      })
      setSumPrice(sumTemp)
    }
  }

  const editImgUrl = url => {
    return url.substring(url.lastIndexOf('/') + 1)
  }

  const handleClickCountCell = (count, indexRow) => {
    if (!editCount) {
      setEditRow(indexRow)
      setCountValue(count)
      setEditCount(true)
    }
  }

  let timer = null

  const handleCountProduct = (countNum, productRow, index) => {
    timer = setTimeout(() => editProductCard(productRow), 1000)
  }

  useEffect(() => {
    if (user.isAuth) {
      fetchBasket(user.user.id).then(data => {
        data[0].forEach((item, index) => {
          Object.assign(item, {
            idRow: data[1][index]['id'],
            count: data[1][index]['count'],
            cost: data[1][index]['cost'],
          })
        })
        product.setBasket(data[0])
        product.basket.forEach(value => {
          // eslint-disable-next-line
          sumTemp += +value.cost
        })
        setSumPrice(sumTemp)
      })
    } else {
      if (localStorage.getItem('cart') !== null) {
        product.setBasket(JSON.parse(localStorage.getItem('cart')))
        product.basket.forEach(value => {
          sumTemp += +value.cost
        })
        setSumPrice(sumTemp)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <h2 style={{ margin: '0px', padding: '0.67em 0' }}>Корзина</h2>
      <h4 style={{ marginTop: '30px', textAlign: 'center' }}>Список товаров</h4>
      <div
        style={{
          minHeight: 100,
          width: '100%',
          textAlign: 'center',
          overflowY: 'auto',
        }}
      >
        <div style={{ overflowY: 'auto' }}>
          <table>
            <thead>
              <tr>
                {columns.map((value, index) => (
                  <th key={index}>{value.headerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {product.basket.map((value, index) => (
                <tr key={v4()}>
                  <th style={{ textAlign: 'center' }}>
                    <ClearIcon
                      className="deleteIcon"
                      alt="Удалить"
                      onClick={() => {
                        user.isAuth ? deleteBasketProd(value.idRow) : deleteBasketProd(index)
                      }}
                    />
                  </th>
                  <th className="basketToProduct" onClick={() => history(PRODUCT_ROUTE + '/' + value.id)}>
                    {value.article}
                  </th>
                  <th className="basketToProduct" onClick={() => history(PRODUCT_ROUTE + '/' + value.id)}>
                    <img
                      src={
                        value.img.includes('hozkomplekt')
                          ? 'https://хозоптсклад.рф/images/' + editImgUrl(value.img)
                          : value.img
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = '//images.weserv.nl?url=' + value.img
                      }}
                      className="prodImg"
                      alt={value.name}
                    ></img>
                  </th>
                  <th
                    className="basketToProduct"
                    onClick={() => {
                      history(PRODUCT_ROUTE + '/' + value.id)
                    }}
                  >
                    {value.name}
                  </th>
                  <th
                    className="countCell"
                    // onClick={() => handleClickCountCell(value.count, index)}
                  >
                    <div className="count-buttons">
                      <button
                        className="button-minus"
                        onClick={() => {
                          value.count - 1 < 1 ? (value.count = 1) : (value.count -= 1)
                          handleCountProduct(value.count, value)
                        }}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        key={'_key_' + value.article}
                        type="text"
                        value={value.count}
                        className="count-input"
                        readOnly
                      />
                      <button
                        className="button-plus"
                        onClick={() => {
                          if (value.count + 1 < 1000000) {
                            value.count += 1
                          } else {
                            value.count = 999999
                          }

                          handleCountProduct(value.count, value)
                        }}
                      >
                        <AddIcon />
                      </button>
                    </div>
                    {/* {editCount && editRow === index ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          key={'_key_' + value.article}
                          type="number"
                          min={1}
                          style={{ width: '50px', fontSize: '20px' }}
                          value={countValue}
                          onChange={e => setCountValue(e.target.value)}
                          onBlur={handleCountProduct}
                        ></input>
                        <Button
                          style={{ width: '20px', padding: '2px 0' }}
                          variant="contained"
                          onClick={() => {
                            value.count = countValue
                            setEditCount(false)
                            editProductCard(value)
                          }}
                        >
                          Ок
                        </Button>
                      </div>
                    ) : (
                      <p> {value.count}</p>
                    )} */}
                  </th>
                  <th>{value.cost} руб.</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {product.basket.length > 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: '20px', fontWeight: '600' }}>Итого:</p>
            <p style={{ fontSize: '18px' }}>{Math.round(sumPrice)} руб.</p>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="btnWrap">
        {product.basket.length ? (
          <Button disabled={blockOrder} variant="contained" onClick={() => setAddPhoneVisible(true)}>
            Заказать
          </Button>
        ) : (
          ''
        )}
      </div>

      <SendOrder show={addPhoneVisible} onHide={() => setAddPhoneVisible(false)} />
    </Container>
  )
})
