import Shop from "./pages/Shop"
import ProductPage from "./pages/ProductPage"
import Admin from "./pages/Admin"
import Basket from "./pages/Basket"
import Auth from "./pages/Auth"
import AboutCompany from "./pages/AboutCompany"
import Contacts from "./pages/Contacts"
import Delivery from "./pages/Delivery"
import OrderPayment from "./pages/OrderPayment"
import Service from "./pages/Service"
import Vacancies from "./pages/Vacancies"
import { ADMIN_ROUTE, BASKET_ROUTE, LOGIN_ROUTE, PRODUCT_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE, ABOUT_ROUTE, CONTACTS_ROUTE, DELIVERY_ROUTE, ORDER_PAYMENT_ROUTE, SERVICE_ROUTE, VACANCIES_ROUTE} from "./utils/consts"

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
]

export const publicRoutes = [
    {
        path: SHOP_ROUTE,
        Component: Shop
    },
    {
        path: SHOP_ROUTE + '/:typeId',
        Component: Shop
    },
    {
        path: SHOP_ROUTE + '/:typeId' + '/:subTypeId',
        Component: Shop
    },
    {
        path: BASKET_ROUTE,
        Component: Basket
    },
    {
        path: PRODUCT_ROUTE + '/:id',
        Component: ProductPage
    },
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
    {
        path: ABOUT_ROUTE,
        Component: AboutCompany
    },
    {
        path: CONTACTS_ROUTE,
        Component: Contacts
    },
    {
        path: DELIVERY_ROUTE,
        Component: Delivery
    },
    {
        path: ORDER_PAYMENT_ROUTE,
        Component: OrderPayment
    },
    {
        path: SERVICE_ROUTE,
        Component: Service
    },
    {
        path: VACANCIES_ROUTE,
        Component: Vacancies
    },
]