export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const BASKET_ROUTE = '/basket'
export const SHOP_ROUTE = '/shop'
export const PRODUCT_ROUTE = '/product'
export const ABOUT_ROUTE = '/about'
export const CONTACTS_ROUTE = '/contacts'
export const DELIVERY_ROUTE = '/delivery'
export const ORDER_PAYMENT_ROUTE = '/order-payment'
export const SERVICE_ROUTE = '/service'
export const VACANCIES_ROUTE = '/vacancies'

export const REACT_APP_API_URL = 'https://api.xn--80aihmfpbisj2a.xn--p1ai/'
// export const REACT_APP_API_URL = 'http://localhost:5000'
