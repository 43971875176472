import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { Context } from '..'
import CreatePrice from '../components/modals/CreatePrice'
import CreateProduct from '../components/modals/CreateProduct'
import CreateSubType from '../components/modals/CreateSubType'
import CreateType from '../components/modals/CreateType'
import DeleteProduct from '../components/modals/DeleteProduct'
import DeleteSubType from '../components/modals/DeleteSubType'
import DeleteType from '../components/modals/DeleteType'

import { fetchProducts, fetchSubTypes, fetchTypes } from '../http/productAPI'
import { check, editOpt, editRole, fetchUsers } from '../http/userAPI'

import PropTypes from 'prop-types'
import { Box, Switch, Tab, Tabs, Typography } from '@mui/material'

import '../scss/pages/_admin.scss'
import { useNavigate } from 'react-router-dom'
import { SHOP_ROUTE } from '../utils/consts'
import { ReplyTwoTone } from '@mui/icons-material'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default observer(function Admin() {
  const { product } = useContext(Context)
  const { user } = useContext(Context)

  const history = useNavigate()

  const [priceVisible, setPriceVisible] = useState(false)

  const [typeVisible, setTypeVisible] = useState(false)
  const [typeDeleteVisible, setTypeDeleteVisible] = useState(false)

  const [subTypeVisible, setSubTypeVisible] = useState(false)
  const [subTypeDeleteVisible, setSubTypeDeleteVisible] = useState(false)

  const [productVisible, setProductVisible] = useState(false)
  const [productDeleteVisible, setProductDeleteVisible] = useState(false)

  const [valueTab, setValueTab] = useState(0)

  const [discount, setDiscount] = useState(0)
  const countDiscount = [0, 1, 2, 3, 4, 5]

  const columnsPhysic = [
    { field: 'id', headerName: 'ID' },
    { field: 'email', headerName: 'Email' },
    { field: 'name', headerName: 'Имя' },
    { field: 'opt', headerName: 'Скидка' },
    { field: 'role', headerName: 'Роль' },
    { field: 'permis', headerName: 'Права админа' },
    { field: 'createdAt', headerName: 'Дата регистрации' },
  ]

  const columnsLawyer = [
    { field: 'id', headerName: 'ID' },
    { field: 'email', headerName: 'Email' },
    { field: 'name', headerName: 'Название компании' },
    { field: 'inn', headerName: 'ИНН' },
    { field: 'opt', headerName: 'Скидка' },
    { field: 'role', headerName: 'Роль' },
    { field: 'permis', headerName: 'Права админа' },
    { field: 'createdAt', headerName: 'Дата регистрации' },
  ]

  const handleChangeRole = (event, typeFace) => {
    const userObj = event.target.parentNode.parentNode.parentNode.parentNode
    let newRole
    if (typeFace === 'physic') {
      newRole = userObj.childNodes[4].innerText === 'ADMIN' ? 'USER' : 'ADMIN'
    } else if (typeFace === 'lawyer') {
      newRole = userObj.childNodes[5].innerText === 'ADMIN' ? 'USER' : 'ADMIN'
    }

    editRole(userObj.childNodes[0].innerText, newRole).then(data => user.setUsers(data.rows))
  }

  const handleChangeOpt = (event, valueOpt) => {
    const userObj = event.target.parentNode.parentNode.childNodes[0].innerText
    editOpt(userObj, Number(valueOpt)).then(data => user.setUsers(data.rows))
  }

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue)
  }

  const checkRole = () => {
    check().then(data => {
      user.setUser(data)
      user.setIsAuth(true)
    })
    return user.user.role !== 'ADMIN' && user.user.role !== 'CREATOR'
  }

  useEffect(() => {
    check().then(data => {
      user.setUser(data)
      user.setIsAuth(true)
    })
    if (user.user.role !== 'ADMIN' && user.user.role !== 'CREATOR') {
      history(SHOP_ROUTE)
    } else {
      fetchTypes().then(data => product.setTypes(data))
      fetchSubTypes().then(data => product.setSubTypes(data))
      fetchUsers().then(data => user.setUsers(data.rows))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container className="wrapped d-flex flex-column">
      <p style={{ fontSize: '18px', marginTop: '10px', marginBottom: '0px' }}>
        <strong>Логин:</strong> {user.user.email}
      </p>
      <p style={{ fontSize: '18px', margin: '0px' }}>
        <strong>Имя:</strong> {user.user.name}
      </p>
      <Button
        variant={'outline-dark'}
        className="btnAdmin"
        onClick={() => {
          checkRole() ? history(SHOP_ROUTE) : setPriceVisible(true)
        }}
      >
        Загрузить прайс-лист
      </Button>

      <div className="wrapBtn">
        <Button
          variant={'success'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setTypeVisible(true)
          }}
        >
          Добавить категорию товара
        </Button>
        <Button
          variant={'danger'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setTypeDeleteVisible(true)
          }}
          disabled={Object.keys(product.types).length === 0 ? true : false}
        >
          Удалить категорию товара
        </Button>
      </div>

      <div className="wrapBtn">
        <Button
          variant={'success'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setSubTypeVisible(true)
          }}
          disabled={Object.keys(product.types).length === 0 ? true : false}
        >
          Добавить подкатегорию товара
        </Button>
        <Button
          variant={'danger'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setSubTypeDeleteVisible(true)
          }}
          disabled={
            Object.keys(product.subTypes).length === 0 || Object.keys(product.types).length === 0 ? true : false
          }
        >
          Удалить подкатегорию товара
        </Button>
      </div>

      <div className="wrapBtn">
        <Button
          variant={'success'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setProductVisible(true)
          }}
          disabled={
            Object.keys(product.subTypes).length === 0 || Object.keys(product.types).length === 0 ? true : false
          }
        >
          Добавить товар
        </Button>
        <Button
          variant={'danger'}
          className="btnAdmin"
          onClick={() => {
            checkRole() ? history(SHOP_ROUTE) : setProductDeleteVisible(true)
          }}
        >
          Удалить товар
        </Button>
      </div>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab aria-selected="true" label="Физ. лица" {...a11yProps(0)} />
            <Tab label="Юр. лица" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          {/* Таблица пользователей */}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p style={{ fontSize: '18px' }}>Список пользователей</p>
            <div style={{ overflowY: 'auto' }}>
              <table>
                <thead>
                  <tr>
                    {columnsPhysic.map((value, index) => (
                      <th key={index}>{value.headerName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {user.users.map(value =>
                    value.email !== user.user.email &&
                    value.email !== 'blad20002000@mail.ru' &&
                    value.face === 'PHYSIC' ? (
                      <tr key={value.id} className="tableRow">
                        <th>{value.id}</th>
                        <th>{value.email}</th>
                        <th>{value.name}</th>
                        <th>
                          <select value={value.opt} onChange={e => handleChangeOpt(e, e.target.value)}>
                            {countDiscount.map(item => (
                              <option key={`discount${item}`} value={item}>
                                {item === 0 ? 'Без скидки' : `Скидка ${item}`}
                              </option>
                            ))}
                          </select>
                          {/* <Switch
                              checked={value.opt}
                              onChange={(e) => { checkRole() ? history(SHOP_ROUTE) : handleChangeOpt(e, value.opt) }}
                            /> */}
                        </th>
                        <th>{value.role}</th>
                        <th>
                          <Switch
                            checked={value.role === 'ADMIN'}
                            onChange={e => {
                              checkRole() ? history(SHOP_ROUTE) : handleChangeRole(e, 'physic')
                            }}
                          />
                        </th>
                        <th>{value.createdAt}</th>
                      </tr>
                    ) : (
                      ''
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p style={{ fontSize: '18px' }}>Список пользователей</p>
            <div style={{ overflowY: 'auto' }}>
              <table>
                <thead>
                  <tr>
                    {columnsLawyer.map((value, index) => (
                      <th key={index}>{value.headerName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {user.users.map(value =>
                    value.email !== user.user.email && value.face === 'LAWYER' ? (
                      <tr key={value.id} className="tableRow">
                        <th>{value.id}</th>
                        <th>{value.email}</th>
                        <th>{value.name}</th>
                        <th>{value.inn}</th>
                        <th>
                          <Switch
                            checked={value.opt}
                            onChange={e => {
                              checkRole() ? history(SHOP_ROUTE) : handleChangeOpt(e, value.opt)
                            }}
                          />
                        </th>
                        <th>{value.role}</th>
                        <th>
                          <Switch
                            checked={value.role === 'ADMIN'}
                            onChange={e => {
                              checkRole() ? history(SHOP_ROUTE) : handleChangeRole(e, 'lawyer')
                            }}
                          />
                        </th>
                        <th>{value.createdAt}</th>
                      </tr>
                    ) : (
                      ''
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
      </Box>

      <CreatePrice show={priceVisible} onHide={() => setPriceVisible(false)} />

      <CreateType show={typeVisible} onHide={() => setTypeVisible(false)} />
      <DeleteType show={typeDeleteVisible} onHide={() => setTypeDeleteVisible(false)} />

      <CreateSubType show={subTypeVisible} onHide={() => setSubTypeVisible(false)} />
      <DeleteSubType show={subTypeDeleteVisible} onHide={() => setSubTypeDeleteVisible(false)} />

      <CreateProduct show={productVisible} onHide={() => setProductVisible(false)} />
      <DeleteProduct show={productDeleteVisible} onHide={() => setProductDeleteVisible(false)} />
    </Container>
  )
})
