import {
    $authHost,
    $host
} from ".";

export const createTypes = async (type) => {
    const {
        data
    } = await $authHost.post('api/type', type)
    return data
}
export const deleteTypes = async ({
    id
}) => {
    const {
        data
    } = await $authHost.delete(`api/type/${id}`)
    return data
}
export const fetchTypes = async () => {
    const {
        data
    } = await $host.get('api/type')
    return data
}


export const createSubTypes = async (subtype) => {
    const {
        data
    } = await $authHost.post('api/subType', subtype)
    return data
}
export const deleteSubTypes = async ({
    id
}) => {
    const {
        data
    } = await $authHost.delete(`api/subType/${id}`)
    return data
}
export const fetchSubTypes = async () => {
    const {
        data
    } = await $host.get('api/subType')
    return data
}

export const createProducts = async (product) => {
    const {
        data
    } = await $authHost.post('api/product', product)
    return data
}
export const deleteProducts = async ({
    id,
    img
}) => {
    const {
        data
    } = await $authHost.delete('api/product/' + id, img)
    return data
}
export const fetchProducts = async (typeId, subTypeId, page, limit = 3, filter = "") => {
    const {
        data
    } = await $host.get('api/product', {
        params: {
            typeId,
            subTypeId,
            page,
            limit,
            filter
        }
    })
    return data
}
export const fetchOneProduct = async (id) => {
    const {
        data
    } = await $host.get('api/product/' + id)
    return data
}

export const editOneProduct = async (product) => {
    const {
        data
    } = await $authHost.put('api/product', product)
    return data
}

export const createPrice = async (price) => {
    const {
        data
    } = await $authHost.post('api/excel', price)
    return data
}