import React, { useContext, useEffect, useState } from 'react'
import { REACT_APP_API_URL, SHOP_ROUTE } from '../utils/consts'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchOneProduct } from '../http/productAPI'
import { createBasket } from '../http/basketAPI'
import Helmet from 'react-helmet'

import { wrappingCost } from '../components/functions/secondaryFunctions'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import EditIcon from '@mui/icons-material/Edit'

import '../scss/pages/_product.scss'
import { Context } from '..'
import { useMediaQuery } from 'react-responsive'
import EditProduct from '../components/modals/EditProduct'
import { useSnackbar } from 'notistack'

export default function ProductPage() {
  const { product } = useContext(Context)
  const { user } = useContext(Context)
  const history = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const [editVisible, setEditVisible] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const [device, setDevice] = useState({ info: [] })
  const [type, setType] = useState('')
  const [subType, setSubType] = useState('')
  const [countProduct, setCountProduct] = useState(1)
  const [costProduct, setCostProduct] = useState(0)
  const { id } = useParams()

  const handleClickVariant = variant => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('Товар успешно добавлен в корзину!', { variant })
  }

  const handleClickOutside = e => {
    e.stopPropagation()
    addProductCard()
    handleClickVariant('success')
  }

  const handleCountProduct = e => {
    // const myPattern = /(?:\b|-)([1-9]{1,2}[0]?|100)\b/
    const myPattern = /^[1-9]\d*$/
    myPattern.test(e.target.value)
      ? e.target.value < 1000000
        ? setCountProduct(e.target.value)
        : setCountProduct(999999)
      : setCountProduct(1)
  }

  const addProductCard = () => {
    if (user.isAuth) {
      createBasket({
        userId: user.user.id,
        productId: device.id,
        count: countProduct,
        cost: costProduct,
      }).then(data => {
        data[0].forEach((item, index) => {
          Object.assign(item, {
            idRow: data[1][index]['id'],
            count: data[1][index]['count'],
            cost: data[1][index]['cost'],
          })
        })
        product.setBasket(data[0])
        setCountProduct(1)
      })
    } else {
      let basket = []
      let find = false

      if (localStorage.getItem('cart') !== null) {
        basket = JSON.parse(localStorage.getItem('cart'))
        for (let i = 0; i < basket.length; i++) {
          if (basket[i].id === device.id) {
            basket[i].count = +basket[i].count + +countProduct
            basket[i].cost = +basket[i].cost + +costProduct
            find = true
            break
          }
        }
        if (!find) {
          basket.push({
            id: device.id,
            article: device.article,
            img: device.img,
            name: device.name,
            cost: costProduct,
            count: countProduct,
          })
        }
      } else {
        basket.push({
          id: device.id,
          article: device.article,
          img: device.img,
          name: device.name,
          cost: costProduct,
          count: countProduct,
        })
      }
      localStorage.cart = JSON.stringify(basket)
      setCountProduct(1)
    }
  }

  const checkRole = () => {
    return user.user['role'] === 'ADMIN' || user.user['role'] === 'CREATOR'
  }

  // ? '//images.weserv.nl?url=' + device.img
  // 'https://хозоптсклад.рф/images/' + editImgUrl(device.img)
  const editImgUrl = url => {
    return url.substring(url.lastIndexOf('/') + 1)
  }

  useEffect(() => {
    fetchOneProduct(Number(id)).then(data => {
      setDevice(data.product)
      setType(data.type)
      setSubType(data.subType)
    })
  }, [id, editVisible])

  useEffect(() => {
    setCostProduct(Math.round(wrappingCost(device.price, countProduct, user.user.opt)))
    // eslint-disable-next-line
  }, [device])

  useEffect(() => {
    setCostProduct(Math.round(wrappingCost(device.price, countProduct, user.user.opt)))
    // eslint-disable-next-line
  }, [countProduct])

  const isSmallScreen = useMediaQuery({ query: '(max-width: 991px' })
  const isScreenIphone = window.matchMedia('(max-width: 991px)')

  const deviceName = (device.name + '').split(' ')

  return (
    <Container className="pt-3" style={{}}>
      <Helmet
        title={device.name}
        meta={[
          {
            name: 'keywords',
            content: `${deviceName}, хозтовары, инструменты, оборудование, строительство, ремонт, красноярск, хозоптсклад, электроинструменты, сварочное, сад, огород, отдых, туризм`,
          },
        ]}
      />
      {!isSmallScreen || !isScreenIphone.matches ? (
        <ArrowBackIcon className="arrowBack" onClick={() => history(-1)}></ArrowBackIcon>
      ) : (
        ''
      )}
      <Row>
        <Col sm={12}>
          <Breadcrumbs style={{ fontSize: '14px', marginLeft: '50px' }} aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              style={{ cursor: 'pointer' }}
              onClick={() => history(SHOP_ROUTE + '/' + type.id)}
            >
              {type.name}
            </Link>
            {subType ? (
              <Link
                underline="hover"
                color="inherit"
                style={{ cursor: 'pointer' }}
                onClick={() => history(SHOP_ROUTE + '/' + type.id + '/' + subType.id)}
              >
                {subType.name}
              </Link>
            ) : (
              ''
            )}
            <Typography style={{ fontSize: '14px' }} color="text.primary">
              {device.name}
            </Typography>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={4} className="d-flex">
          {device.img && !device.img.includes('NONE') ? (
            <Image
              alt={device.name}
              style={{
                margin: 'auto',
                objectFit: 'contain',
                // cursor: 'pointer',
              }}
              width={200}
              height={200}
              src={
                device.img.includes('hozkomplekt')
                  ? 'https://хозоптсклад.рф/images/' + editImgUrl(device.img)
                  : device.img
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '//images.weserv.nl?url=' + device.img
              }}
              // onClick={() => setShowImage(!showImage)}
            />
          ) : (
            <Card
              className="d-flex flex-column align-items-center justify-content-around"
              style={{
                width: 200,
                height: 200,
                fontSize: 32,
                border: '5px solid lightgray',
                margin: 'auto',
              }}
            >
              <h3 style={{ textAlign: 'center' }}>Фото не загружено</h3>
            </Card>
          )}
          {showImage && (
            <div className="wrapPopup" onClick={() => setShowImage(false)}>
              <Image
                alt={device.name}
                className="imagePopup"
                style={{ margin: 'auto', objectFit: 'contain' }}
                src={
                  device.img.includes('hozkomplekt')
                    ? 'https://хозоптсклад.рф/images/' + editImgUrl(device.img)
                    : device.img
                }
                onClick={() => setShowImage(false)}
              />
            </div>
          )}
        </Col>
        <Col md={6} lg={4} className="d-flex flex-column align-items-left" style={{ justifyContent: 'center' }}>
          {checkRole() ? (
            <div className="editProduct">
              <EditIcon className="editIcon" onClick={() => setEditVisible(true)} />
            </div>
          ) : (
            ''
          )}
          <p className="nameProduct">
            <strong>Название:</strong> {device.name}
          </p>
          <p>
            <strong>Артикул:</strong> {device.article}
          </p>
        </Col>
        <Col md={12} lg={4}>
          <Card
            className="d-flex flex-column align-items-center justify-content-around"
            style={{
              width: 300,
              height: 220,
              border: '5px solid lightgray',
              margin: 'auto',
            }}
          >
            <h3>{costProduct} руб.</h3>
            <input
              className="countInput"
              type={'number'}
              value={countProduct}
              min={0}
              max={100}
              onChange={e => setCountProduct(e.target.value)}
              onBlur={handleCountProduct}
            ></input>
            {device.availability ? (
              <p style={{ color: '#8ac926' }}>Есть в наличии</p>
            ) : (
              <p style={{ color: '#e71d36' }}>Нет в наличии</p>
            )}
            <Button variant={'outline-dark'} onClick={handleClickOutside}>
              Добавить в корзину
            </Button>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex flex-column m-3">
        {device.info.length > 0 ? <h1>Характеристики</h1> : ''}

        {device.info.map((info, index) => (
          <Row
            key={info.id}
            style={{
              background: index % 2 === 0 ? 'lightgray' : 'transparent',
              padding: 10,
            }}
          >
            {info.title}: {info.description}
          </Row>
        ))}
      </Row>

      <EditProduct show={editVisible} onHide={() => setEditVisible(false)} device={device} />
    </Container>
  )
}
