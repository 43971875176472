import React from 'react'
import { Card, Container } from 'react-bootstrap'

export default function Service() {
  return (
    <Container>
      <h2 style={{ textAlign: 'center' }}>СЕРВИС</h2>
      <Card style={{ padding: '30px' }}>
        <p>
          Наш севисный центр находится по адресу:<br></br>
          г.Красноярск ул.Караульная 5 в
        </p>
        <p>Мы осуществляем гарантийный ремонт в течении 12 месяцев на все электробытовые приборы, электро-бензо инструмент и прочее.</p>
        <p>– послегарантийный ремонт  мы не осуществляем</p>
        <p>В нашем сервисном центре имеется <strong>огромный выбор запчастей в наличии.</strong></p>
        <p>На весь отремонтированный инструмент распространяется гарантия.</p>
        <p>Наш сервисный центр является официальным дилером многих фирм-производителей, Вам не придется ждать месяцами пока придет запасная часть.</p>
        <p>Наши мастера проходят каждый год обучение на предприятиях фирм-изготовителей, поэтому имеют большой навык и опыт работы.</p>
        <p>Тел.+7 (391) 272-69-40</p>
        <p>
          <strong>
            График  работы: пн-пт 10.00-17.00
          </strong>
        </p>
      </Card>
    </Container>
  )
}
