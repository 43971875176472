import React, { useState } from 'react'
import { Card, Container, Form } from 'react-bootstrap'

import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import "../scss/pages/_contacts.scss"
import { sendContactForm } from '../http/contactsAPI';
import SuccessEmail from '../components/modals/MessageModal';

export default function Contacts() {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')

  const [messageModal, setMessageModal] = useState(false)
  const [textModal, setTextModal] = useState('')


  const sendEmail = () => {
    if (name !== '' && phone !== '' && email !== '') {
      const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!patternEmail.test(email)) {
        setEmail('');
        setTextModal("Неверный формат Email")
        setMessageModal(true)
      } else {
        sendContactForm({ name: name, phone: phone, email: email, comment: comment }).then(data => {
          setTextModal("Заявка на обратную связь успешно отправлена")
          setMessageModal(true)
          setName('')
          setPhone('')
          setEmail('')
          setComment('')
        })
      }
    } else {
      setTextModal("Вы заполнили не все поля!")
      setMessageModal(true)
    }
  }

  return (
    <Container>
      <h2 style={{ textAlign: 'center' }}>КОНТАКТЫ</h2>
      <Card>
        <div className='wrap'>
          <div className='contactInfo'>
            <h4 className='title'>Контактная информация</h4>

            <p className='subTitle'>Адрес</p>
            <div className='wrapRow'>
              <LocationOnIcon></LocationOnIcon>
              <p>г.Красноярск ул. Караульная 5а стр 2</p>
            </div>

            <p className='subTitle'>Телефон</p>
            <div>
              <div className='wrapRow'>
                <CallIcon></CallIcon>
                <p>8-967-612-07-22</p>
              </div>
              <div className='wrapRow'>
                <CallIcon></CallIcon>
                <p>8 (391) 272-07-22</p>
              </div>
              <div className='wrapRow'>
                <WhatsAppIcon></WhatsAppIcon>
                <p>8-967-612-07-22 WhatsApp</p>
              </div>
            </div>

            <p className='subTitle'>Email</p>
            <div className='wrapRow'>
              <MailIcon></MailIcon>
              <p>hozoptsklad@mail.ru</p>
            </div>

            <p className='subTitle'>Режим работы</p>
            <div className='wrapRow'>
              <WatchLaterIcon></WatchLaterIcon>
              <p>Понедельник-пятница с 09:00-18:00 суббота только доставка</p>
            </div>
          </div>
          <div className='contactForm'>
            <h4 className='title'>Контактная форма</h4>
            <Card className='contactForm'>
              <Form className="d-flex flex-column gap-2">
                <div className='myRow'>
                  <TextField
                    required
                    id="standard-basic"
                    label="Имя"
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    required
                    id="standard-basic"
                    label="Телефон"
                    variant="standard"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <TextField
                  required
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  id="filled-multiline-flexible"
                  label="Комментарий"
                  variant="standard"
                  multiline
                  maxRows={5}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <Button
                  color='warning'
                  variant="contained"
                  onClick={sendEmail}
                >
                  Отправить
                </Button>
              </Form>
            </Card>
          </div>
        </div>
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Af671ee195e3dcaf8a0692b7a7501494a80b449e3cd405d18c20e62bffd96d40f&amp;source=constructor"
          width="100%"
          height="400"
          frameBorder="0"
          title='map'
        ></iframe>
      </Card>

      <SuccessEmail show={messageModal} onHide={() => setMessageModal(false)} messageText={textModal} />
    </Container >
  )
}
