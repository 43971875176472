import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Delivery() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const direction1 = ["Красноярск", "Старцево", "Сибиряк", "Таскино", "Миндерла", "Сухобузимское", "Шила", "Бартат", "Большая мурта", "Раздольная", "Мокрушинское", "Галанино", "Казачинское", "Шапкино", "Новокарчино", "Аболаковское", "Лесосибирск", "Новоенисейск", "Енисейск",
  ]
  const direction2 = ["Красноярск", "Слизнево", "Овсянка", "Дивногорск", "Балахта", "Новоселово", "Первомайское", "Знаменка", "Приморск", "Черногорск", "Абакан", "Минусинск", "Шушенское", "Саяногорск"]

  const direction3 = ["Красноярск", "Козулька", "Боготол", "Шарыпово", "Тяжинский", "Мариинск", "Кемерово"]

  const direction4 = ["Красноярск", "Железногорск", "Сосновоборск", "Уяр", "Заозерный", "Большая уря", "Канск", "Абан", "Иланский", "Нижний ингаш", "Тинской", "Нижняя пойма"]

  const direction5 = ["Красноярск", "Кедровый", "Памяти 13 борцов", "Козулька", "Ачинск"]

  return (
    <Container>
      <h2 style={{ textAlign: "center" }}>ДОСТАВКА</h2>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab style={{}} label="Самовывоз со склада" {...a11yProps(0)} />
            <Tab label="Экспресс-доставка" {...a11yProps(1)} />
            <Tab label="Доставка по Красноярскому краю, Хакасии, р. Тыва" {...a11yProps(2)} />
            <Tab label="Доставка ТК" {...a11yProps(3)} />
            <Tab label="Доставка Почтой России" {...a11yProps(4)} />
            <Tab label="Правило получения товаров" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <p>Вы можете забрать свой заказ самостоятельно на любом из наших пунктов выдачи по адресам:</p>
          <ul>
            <li>г.Красноярск ул. Караульная 5 а стр. 2</li>
          </ul>
          <p>Для этого Вам необходимо оформить заказ на сайте или у оператора до 16.00 (товар можно забрать в этот же день с 10 до 17 (пн-сб))</p>
          <p>Ваш заказ хранится в течение 2-х дней и по истечении этого срока будет аннулирован. Если Вы хотите продлить срок хранения или поменять способ доставки, просто позвоните нам.</p>
          <p>Доставка осуществляется по всей России и в страны СНГ. После оформления заказа с вами свяжется менеджер для подтверждения заказа и уточнения наличия товаров в заказе.</p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <p>Экспресс – это быстро!</p>
          <p>Экспресс доставка от  ХОЗОПТСКЛАД.РФ – это доставка по городу Красноярску в течении 2х часов.</p>
          <p>Стоимость срочной доставки по Красноярску-500 рублей.</p>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <p><strong>Доставка по Красноярскому краю, р.Хакасии и Тыва происходит один раз в неделю и зависит от направлений маршрутов.</strong></p>
          <p>Доставка по Красноярскому краю осуществляется после  100 % оплаты за выбранные Вами товары с помощью онлайн перевода денежных средств на счёт нашей фирмы.</p>
          <p>Внимание перед оплатой напишите нам в комментарии, или на WhatsApp,  либо свяжитесь с оператором и узнайте о наличии товара.
            Доставка оплачивается отдельно водителю, после распаковки и осмотра  комплектации товара,  на наличии  механических повреждений.</p>
          <p>Мы осуществляем доставку по населённым пунктам, которые находятся от трассы не более 10-ти  км по таким направлениям как:</p>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <ul>
              {direction1.map((value, index) =>
                <li key={index}>{value}</li>
              )}
            </ul>
            <ul>
              {direction2.map((value, index) =>
                <li key={index}>{value}</li>
              )}
            </ul>
            <ul>
              {direction3.map((value, index) =>
                <li key={index}>{value}</li>
              )}
            </ul>
            <ul>
              {direction4.map((value, index) =>
                <li key={index}>{value}</li>
              )}
            </ul>
            <ul>
              {direction5.map((value, index) =>
                <li key={index}>{value}</li>
              )}
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <p>Для доставки этим способом мы пользуемся компаниями “СДЭК”, «Деловые Линии», «ПЭК», «EMC».<br></br>
            Доставка с помощью ТК осуществляется после расчета стоимости оператором.</p>
          <p>Примерную стоимость и срок доставки вы можете узнать на сайте ТК или обратиться к нам.<br></br>
            Отправляем только оплаченный товар.</p>
          <p><strong>Условия</strong></p>
          <p>Просим Вас внимательно и аккуратно заполнять Ваши данные: ФИО, полный адрес доставки, индекс и контактный телефон. Неправильные или неполные контактные данные могут привести к задержке с получением заказа!<br></br>
            Обязательно перед оплатой заказа получите подтверждение нашего сотрудника о наличии товара и возможности их доставки в Ваш регион.</p>
          <p>
            Деловые линии - <a style={{ color: "#ff781f" }} href='http://www.dellin.ru/contacts/47'>http://www.dellin.ru/contacts/47</a><br></br>
            ПЭК - <a style={{ color: "#ff781f" }} href='http://pecom.ru/ru/'>http://pecom.ru/ru/</a><br></br>
            ЕМС доставка - <a style={{ color: "#ff781f" }} href='http://www.emspost.ru/ru/'>http://www.emspost.ru/ru/</a>
          </p>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <p>Стоимость доставки Почтой России рассчитывается для каждого заказа индивидуально и зависит от места назначения, веса и ценности отправления. Оплата за товар производится 100% перед отправкой к заказчику.</p>
          <p>При получении в почтовом отделении товара, Вы оплачиваете  доставку.</p>
          <p>Комплектность заказа и целостность товара проверяется клиентом на пункте выдачи (отделении почты) при получении заказа.</p>
          <p><strong>ВАЖНО!</strong></p>
          <p>При оформлении заказа на сайте проверяйте правильность указываемых Вами почтовых адресов и данных получателя. В случае возникновения сомнений в правильности указанных Вами сведений, пожалуйста, указывайте дополнительную информацию в поле Комментарии к заказу.</p>
          <p>ХОЗОПТСКЛАД.РФ  не несёт ответственности за неправильно указанный адрес и контактные данные.</p>
          <p>После того, как Почта России предоставит отчёт об отправке заказа, на Ваш электронный адрес автоматически высылается письмо с номером почтового отправления.</p>
          <p><strong>Способ доставки и сроки доставки</strong></p>
          <p>Заказ отправляется ценной посылкой или ценной бандеролью в зависимости от веса и габаритов заказа. Предельный вес отправления 20 кг. Заказы весом от 20 кг. делятся на несколько отправлений. Срок доставки ориентировочно от 2 до 5 недель после передачи заказа на Почту России, в зависимости от удаленности региона. Подробнее о контрольных сроках пересылки в ваш населенный пункт на сайте <a style={{ color: "#ff781f" }} href='https://www.pochta.ru/'>Почты России</a>.</p>
          <p>ХОЗОПТСКЛАД.РФ не несет ответственности за сроки доставки Почтой России.</p>
          <p>Отследить местонахождение почтового отправления Вы можете:</p>
          <p>
            <ul>
              <li>на сайте Почты России;</li>
              <li>через справочную службу Почты России тел. 8-800-2005-888;</li>
              <li>заказ хранится в почтовом отделении 14 дней.</li>
            </ul>
          </p>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <p>Доставка осуществляется курьерской службой, в связи с этим курьер не обязан знать характеристики и свойства доставляемого товара, также как проверять его работоспособность. Вы не вправе отказаться от доставки, если курьер отказался проверять работоспособность, либо ожидать Вас пока Вы проверите товар. Перед передачей.</p>
          <p>
            После доставки, товар (в присутствии курьера) распаковывается, и осматривается.
            <ul>
              <li>Принимая товар, проверьте комплектацию всего заказа и каждого товара в отдельности.</li>
              <li>Вы должны убедиться в отсутствии на товарах видимых механических повреждений после перевозки.</li>
              <li>Товар не заносится курьером в квартиру.</li>
            </ul>
          </p>
          <p>
            Сотрудники доставки не производят проверку работоспособности доставленного товара, его установку и подключение.
            <ul>
              <li>Проверьте наличие и правильность заполнения всех сопроводительных документов, таких как: товарная накладная, гарантийный талон(ы).</li>
            </ul>
          </p>
          <p>После расчета с курьером Клиент подписывает накладную, чем подтверждает, что претензий к качеству и комплектации заказа не имеет. Вместе с заказом вы получаете накладную, содержащую все товарные позиции вашего заказа.</p>
        </TabPanel>
      </Box>
    </Container>
  );
}
