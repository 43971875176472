import {
    $authHost,
    $host
} from ".";
import jwt_decode from "jwt-decode";

export const registration = async (email, password, face, name = "Гость", inn=null) => {
    const {
        data
    } = await $host.post('api/user/registration', {
        email,
        password,
        name,
        inn,
        face,
        role: 'USER'
    })
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}
export const login = async (email, password) => {
    const {
        data
    } = await $host.post('api/user/login', {
        email,
        password
    })
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}
export const check = async () => {
    const {
        data
    } = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}
export const fetchUsers = async () => {
    const {
        data
    } = await $authHost.get('api/user')
    return data
}

export const editRole = async (id, role) => {
    const {
        data
    } = await $authHost.put('api/user/role', {id, role})
    return data
}

export const editOpt = async (id, opt) => {
    const {
        data
    } = await $authHost.put('api/user/opt', {id, opt})
    return data
}