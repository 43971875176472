import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { BrowserRouter} from "react-router-dom"
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import {observer} from 'mobx-react-lite'
import {check} from "./http/userAPI";
import {Context} from ".";
import {Spinner} from "react-bootstrap";
import Footer from "./components/Footer";
import { useMediaQuery } from 'react-responsive'
import BottomNavbar from "./components/BottomNavigation";

const App = observer(() => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 991px'})
  const isScreenIphone = window.matchMedia("(max-width: 991px)")

  useEffect(() => {
    setTimeout(() => {
      check().then(data => {
        user.setUser(data)
        user.setIsAuth(true)
      }).catch(err => {
        console.log(err.response.data.message)
      }).finally(() => setLoading(false))
    }, [])

  }, [user])

  if (loading) {
    return <Spinner style={{position: "absolute", left: "50%", top: "50%"}} animation = {
      "grow"
    }
    />
  }

  return ( 
    <BrowserRouter>
      <NavBar/>
      <AppRouter/>
      {isSmallScreen || isScreenIphone.matches ? <BottomNavbar/> : <Footer/>}
    </BrowserRouter>
  );
})

export default App;