import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ProductStore from './store/ProductStore'
import UserStore from './store/UserStore'

import '../src/scss/_global.scss'
import { SnackbarProvider } from 'notistack'

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Context.Provider
    value={{
      user: new UserStore(),
      product: new ProductStore(),
    }}
  >
    <SnackbarProvider maxSnack={1}>
      <App />
    </SnackbarProvider>
  </Context.Provider>
)
