import {
    makeAutoObservable
} from "mobx"

export default class ProductStore {
    constructor() {
        this._types = []
        this._subTypes = []
        this._products = []
        this._basket = []
        this._selectedType = {}
        this._selectedSubType = {}
        this._selectedProduct = {}
        this._searchString = ""
        this._page = 1
        this._tempPage = 1
        this._totalCount = 0
        this._limit = 18
        makeAutoObservable(this)
    }

    setTypes(types) {
        this._types = types
    }
    setSubTypes(subTypes) {
        this._subTypes = subTypes
    }
    setProducts(products) {
        this._products = products
    }
    setBasket(basket) {
        this._basket = basket
    }

    setSearchString(searchString) {
        this._searchString = searchString
    }

    setSelectedType(type) {
        this.setPage(1)
        this._selectedType = type
    }
    setSelectedSubType(subType) {
        this.setPage(1)
        this._selectedSubType = subType
    }
    setSelectedProduct(product) {
        this.setPage(1)
        this._selectedProduct = product
    }
    setPage(page) {
        this._page = page
    }
    setTempPage(tempPage) {
        this._tempPage = tempPage
    }
    setTotalCount(count) {
        this._totalCount = count
    }
    setLimit(limit) {
        this._limit = limit
    }

    get types() {
        return this._types
    }
    get subTypes() {
        return this._subTypes
    }
    get products() {
        return this._products
    }
    get basket() {
        return this._basket
    }
    get searchString() {
        return this._searchString
    }

    get selectedType() {
        return this._selectedType
    }
    get selectedSubType() {
        return this._selectedSubType
    }
    get selectedProduct() {
        return this._selectedProduct
    }
    get totalCount() {
        return this._totalCount
    }
    get page() {
        return this._page
    }
    get tempPage() {
        return this._tempPage
    }
    get limit() {
        return this._limit
    }
}